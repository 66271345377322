import clsx from 'clsx';

export type IContainerProps = {
    className?: string;
    children: React.ReactNode;
};

const Container = (props: IContainerProps) => {
    const { className } = props;
    return (
        <div
            {...props}
            className={clsx(
                'mx-auto max-w-outer px-4 sm:px-6 lg:px-8',
                className
            )}
        />
    );
};
export default Container;
